<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div class="row q-gutter-lg">
        <base-input-text v-bind="formInputProps('nom')" color="users"
          v-model="formData.nom" ref="nom" class="col col-5" />

        <base-input-text v-bind="formInputProps('prenom')" color="users"
          v-model="formData.prenom" ref="prenom" class="col col-5" />

        <base-input-text v-bind="formInputProps('email')" color="users"
          v-model="formData.email" ref="email" class="col col-5" />

        <base-input-text v-bind="formInputProps('password')" color="users" :readonly="!!user"
          v-model="formData.password" ref="password" class="col col-5" />
      </div>
    </div>

    <div class="col col-2 q-gutter-sm">
      <q-list v-if="isAdmin" class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('role')" color="users"
            v-model="formData.role" ref="role" />
        </q-item>

        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('region_id')" color="users"
            v-model="formData.region_id" ref="region_id" />
        </q-item>
      </q-list>

      <q-btn color="users full-width" size="sm" unelevated type="submit">{{$t('form.submit')}}</q-btn>
      <q-btn color="users full-width" size="sm" outline @click="$router.go(-1)">{{$t('form.cancel')}}</q-btn>
      <q-btn color="negative full-width" size="sm" unelevated icon-right="delete"
        v-if="isAdmin" @click.native="destroy">{{$t('users.delete_user')}}</q-btn>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../mixins'

export default {
  name: 'UserDetails',
  mixins: [BaseForm],
  props: {
    'form_model': { type: String, default: 'users' },
    'action': { type: String, default: 'users/saveUser' }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      user: 'users/getCurrentUser'
    }),

    title () {
      return !this.$route.params.id ? this.$t('users.add_user') : this.$t('users.edit_user')
    },

    statusOptions () {
      return ['active', 'inactive']
    }
  },

  watch: {
    user: {
      handler (newVal, oldVal) {
        this.formData = {
          id: newVal.id,
          ...this.formData,
          ...this.formFields.map(({ id, options }) => ({ id, options }))
            .reduce((acc, { id, options }) => ({
              ...acc, [id]: options ? options.find(({ value }) => value === newVal[id]) : newVal[id]
            }), {})
        }

        this.formData['role'] = { label: this.$t(`roles.${newVal['role']}`), value: newVal['role'] }

        if (this.$route.params.id) {
          this.$store.dispatch('users/fetchFormFields', { droits_title: 'update' })
          this.formData.id = this.$route.params.id
        }
      }
    }
  },

  methods: {
    onSave () {
      this.$router.go(-1)
    },

    destroy () {
      this.$confirm(this.$t('users.delete_user_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('users/deleteUser', this.$route.params.id).then(() => {
          this.notifySuccess('users.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
